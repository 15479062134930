.secondaryButton {
    border-radius: 5px !important;
    background-color: #FDFEFF !important;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.10) !important;
    text-transform: capitalize !important;
}

.secondaryButton:hover {
    background: #D3DFF1 !important;
}

.secondaryButton:active {
    background-color: #F0F6FF !important;
}
