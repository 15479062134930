.drawerContainer {
    width: 72px;
}

/* Custom styles for the drawer */
.menuDrawer {
    /* Set the width when the drawer is open */
    width: 256px;
    transition: width 0.3s ease;
    height: 100vh;
    background-color: #659AE9;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    flex-direction: column;
}

/* Add the width when the drawer is closed (0px) */
.menuDrawer.closed {
    width: 72px;
}

/* Optional: Apply a CSS transition to the icon as well */
.menuIcon {
    color: white;
    transition: transform 0.3s ease;
}

/* Rotate the icon 180 degrees when the drawer is open */
.menuDrawer.open .menuIcon {
    transform: rotate(180deg);
}

.logo {
    margin-top: 20px;
    margin-bottom: 72px;
    height: 40px;
}

.menu {
    padding: 16px;
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 16px;
}

.logoutContainer {
    padding: 0 16px;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.menu.active {
    background-color: rgba(250, 249, 255, 0.10);
    border-radius: 16px;
}

.menuItemIcon {
    padding-left: 8px;
    height: 24px;
}

.menuItemText {
    padding-left: 16px;
}

.menuList {
    flex: 1;
}

.logout {
    display: flex;
    align-items: center;
}
