
.inputContainer {
    background: rgba(110, 113, 145, 0.10) !important;
    border-radius: 5px;
    pointer-events: none;
}

.inputContainer .MuiOutlinedInput-root {
    border-color: rgba(72, 68, 68, 0.70) !important;
}

.inputContainer input::placeholder {
    color: #A5A3A9 !important;
}

.searchContainer {
    display: flex;
}
