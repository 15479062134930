.primaryButton {
    border-radius: 5px !important;
    background: linear-gradient(207deg, #659AE9 0%, rgba(67, 142, 253, 0.97) 100%) !important;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.10) !important;
    text-transform: capitalize !important;
}

.primaryButton:hover {
    background: linear-gradient(207deg, #5D91DE 0%, rgba(34, 101, 201, 0.97) 100%, rgba(34, 101, 201, 0.97) 100%) !important;
}

.primaryButton:active {
    background-color: #2360BB !important;
}
