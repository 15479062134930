.actionButton {
    padding: 12px;
}

.navigationButton {
    border: 1px solid #DFE3E8 !important;
    height: 30px;
    width: 30px;
}

.link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.mrnNo {
    margin-left: 8px;
    text-decoration: underline;
}

.evenRow {
    background-color: #EBF3FC;
}

.oddRow {
    background-color: #FDFEFF;
}

