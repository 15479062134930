.category {
    margin-right: 8px;
    margin-bottom: 8px;
    display: inline-block;
}

.addTaskIcon {
    margin-right: 12px;
}

.emptyButton {
    width: 34px;
}
