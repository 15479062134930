.details {
    border: 1px solid #ebebeb;
    border-radius: 5px;
}

.accordionTitle {
    border-bottom: 1px solid #ebebeb;
    background-color: #D0E0F8;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.container {
    overflow: scroll;
    height: calc(100vh - 88px);
}
