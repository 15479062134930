.pageContainer {
    margin-top: 48px;
    height: calc(100vh - 48px);
    overflow-x: hidden;
    overflow-y: auto;
}

.tableContainer {
    overflow: scroll;
}
