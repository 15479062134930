body {
    margin: 0;
    font-family: Inter, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.MuiDataGrid-columnHeaderTitle {
    font-weight: 600 !important;
    color: #484444 !important;
    font-size: 14px;
}

.MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within {
    outline: none !important;
}
