.multiSelectContainer {
    margin-right: 12px;
}

.menuItem {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    min-width: 200px;
}

.button {
    cursor: pointer;
    text-decoration: underline;
    font-weight: 500 !important;
}

.actionContainer {
    border-top: 1px solid #EEF5FB;
    padding: 8px 8px 0 8px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.menuContainer {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: 400px;
}

.menuList {
    flex: 1;
    overflow: auto;
    border-top: 1px solid #EEF5FB;
}
