.appContainer {
    height: 100vh;
}

.mainContainer {
    width: calc(100% - 72px);
    margin-left: 72px;
    height: 100vh;
    overflow: hidden;
}
